<template>
    <section class="compras-listado">
        <titulo-divisor titulo="Utilidad de paises">
            <div class="d-flex">
                <div class="icon-option">
                    <el-tooltip content="Agregar pais" effect="light">
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="openModalCreateCountryUtility">
                            <i class="icon-plus f-30 text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filtersCount == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="openModalContryFilter">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filtersCount > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="openModalContryFilter">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2 cr-pointer">
                            <p class="f-12">{{filtersCount}}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1" @click="clearFilter" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general 
        :mostrarBuscador="false" 
        alturaTabla="calc(100vh - 350px)" 
        layout-servidor="prev, pager, next"
        :usar-servidor="true" 
        :usar-paginacion="true" 
        :servidor-data="countries" 
        @paginar="getCountries" 
        >
            <el-table-column label="Id" prop="numOrden" align="center" width="80">
                <template slot-scope="scope">
                    <p class="f-12 cr-pointer f-600" @click="openModalEditCountryUtility(scope.row)">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Paises ingles" align="center">
                <template slot-scope="scope">
                    <p class="f-12 f-600 tres-puntos">
                        {{ scope.row.pais && scope.row.pais.nombre_ingles || '-'}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Bandera" width="100" align="center">
                <template slot-scope="scope">
                    <p class="f-12">
                        <img v-if="scope.row.pais.bandera" class="flag-size" :src="scope.row.pais.bandera | helper-storage-manager" alt="">
                        <i v-else class="icon-diving-scuba-flag f-40"/>
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Porcentaje utilidad" align="center">
                <template slot-scope="scope">
                    <p class="f-12 tres-puntos">
                        {{ scope.row.utilidad }}%
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Código" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-uppercase">
                        {{ scope.row.pais && scope.row.pais.codigo }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <modal-filter-lateral ref="modalFilterLateral" />
        <modal-create-country-utility ref="modalCreateCountryUtility"/>
        <modal-edit-country-utility ref="modalEditCountryUtility"/>
    </section>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import modalFilterLateral from './partials/modalFilterLateral'
import modalCreateCountryUtility from './partials/modalCreateCountryUtility'
import modalEditCountryUtility from './partials/modalEditCountryUtility'
export default {
    components: { 
        modalFilterLateral,
        modalCreateCountryUtility, 
        modalEditCountryUtility 
    },
    computed: {
        ...mapGetters({
            filter: 'configurar/paises/paises/filter',
            countries: 'configurar/paises/paises/countries',
        }),
        filtersCount(){
            return this.filter.count();
        },
    },
    created(){
        this.$try(async () => {
            this.getBreadcumbs(['configurar.paises']);
            this.sync_selects(['Action_get_select_countries'])
            await this.getCountries();
        })
    },
    methods:{
        ...mapActions({
            getCountries: 'configurar/paises/paises/getCountries',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            clearFilter: 'configurar/paises/paises/clearFilter',
            sync_selects: 'selects/selects/sync',
        }),
        openModalContryFilter(){
            this.$refs.modalFilterLateral.toggle()
        },
        openModalCreateCountryUtility(){
            this.$refs.modalCreateCountryUtility.toggle()
        },
        openModalEditCountryUtility(country){
            this.$refs.modalEditCountryUtility.toggle(country)
        }
    }
}
</script>

<style scoped>
.flag-size{
    width: 44px;
}
</style>