<template>
    <modal-lateral ref="modalFilterLateral" titulo="Filtro">
        <div class="row mx-0 justify-content-center my-2">
            <div class="col-11 my-4">
                <p class="input-label-top">Elija pais</p>
                <el-select v-model="filter.pais" clearable placeholder="Seleccionar pais" size="small" class="w-100">
                    <el-option v-for="item in select_countries" :key="item.id" :label="item.pais" :value="item.pais" />
                </el-select>
            </div>
            <div class="col-11 my-4">
                <p class="input-label-top">Rango de porcentaje</p>
                <el-slider v-model="utilityRange" range :min="0" :max="10000" :step="50" />
            </div>
        </div>
        <div class="botones">
            <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter">
                Limpiar
            </button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterCountries">
                Filtrar
            </button>
        </div>
    </modal-lateral>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            select_countries: 'selects/selects/select_countries',
        }),
        filter: {
            get(){
                return this.$store.getters['configurar/paises/paises/filter'];
            },
            set(){
                this.$store.commit('configurar/paises/paises/set_filter', val)
            }
        },
        utilityRange:{
            get(){
                return [this.filter.utilidad_minima, this.filter.utilidad_maxima]
            },
            set(val){
                this.filter.utilidad_minima = val[0]
                this.filter.utilidad_maxima = val[1]
            }
        }
    },
    methods: {
        ...mapActions({
            clearFilter: 'configurar/paises/paises/clearFilter',
            getCountries: 'configurar/paises/paises/getCountries',
            setPage: 'configurar/paises/paises/setPage',
        }),
        filterCountries(){
            this.getCountries();
            this.toggle();
        },
        toggle(){
            this.$refs.modalFilterLateral.toggle();
        }
    }
}
</script>