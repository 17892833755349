<template>
    <Modal ref="modalEditCountryUtility" titulo="Edite la utilidad del pais" adicional="Guardar" @adicional="editCountryUtility">
        <ValidationObserver ref="validator">
            <ValidationProvider v-slot="{errors}" name="porcentaje de utilidad" rules="required|min_value:0|max_value:100">
                <div class="row m-3 f-15">
                    <div class="col-auto my-auto">
                        <p class="input-label-top f-12">Porcentaje</p>
                    </div>
                    <div class="col">
                        <el-input v-model="model.utilidad" class="w-100" size="small" />
                    </div>
                </div>
                <div class="row mx-3">
                    <div class="col-12">
                        <vee-error class="pl-2" :text="errors[0]" />
                    </div>
                </div>
            </ValidationProvider>
        </ValidationObserver>
    </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { CountryUtility } from '~/domain/entities/configurar/paises/CountryUtility'
export default {
    data(){
        return {
            model: {
                id_pais: null,
                utilidad: ''
            }
        }
    },
    methods:{
        ...mapActions({
            updateCountryUtility: 'configurar/paises/paises/updateCountryUtility'
        }),
        toggle(country){
            this.$try(() => {
                if(country instanceof CountryUtility){
                    const { id, id_pais, utilidad } = country
                    this.model = { id, id_pais, utilidad }
                }
    
                this.$refs.modalEditCountryUtility.toggle()
            })
        },
        async editCountryUtility(){
            const valid = await this.$refs.validator.validate();

            if(!valid) return

            this.updateCountryUtility(this.model)

            this.toggle();
        }
    }
}
</script>