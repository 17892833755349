<template>
    <modal ref="modalAddCountryUtility" titulo="Agregue pais" adicional="Guardar" @adicional="addCountryUtility">
        <ValidationObserver ref="validator">
            <ValidationProvider v-slot="{errors}" name="pais" rules="required">
                <div class="row m-3 f-15">
                    <div class="col-auto my-auto">
                        <p class="input-label-top f-12">Elija pais</p>
                    </div>
                    <div class="col">
                        <el-select v-model="country.id_pais" :filterable="true" class="w-100" size="small" clearable>
                            <el-option v-for="country in select_countries" :key="country.id" :label="country.pais" :value="country.id" size="small" />
                        </el-select>
                    </div>
                </div>
                <div class="row mx-3">
                    <div class="col-12">
                        <vee-error :text="errors[0]" />
                    </div>
                </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{errors}" name="porcentaje de utilidad" rules="required|min_value:0|max_value:100">
                <div class="row m-3 f-15">
                    <div class="col-auto my-auto">
                        <p class="input-label-top f-12">Ingrese el porcentaje de utilidad</p>
                    </div>
                    <div class="col">
                        <el-input v-model="country.utilidad" class="w-100" size="small" />
                    </div>
                </div>
                <div class="row mx-3">
                    <div class="col-12">
                        <vee-error class="pl-2" :text="errors[0]" />
                    </div>
                </div>
            </ValidationProvider>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CountryUtilityFactory } from '~/domain/entities/configurar/paises/factories/CountryUtilityFactory'
export default {
    data(){
        return {
            country: CountryUtilityFactory.instantiateEmpty()
        }
    },
    computed:{
        ...mapGetters({
            select_countries: 'selects/selects/select_countries',
        })
    },
    methods: {
        ...mapActions({
            createCountryUtility: 'configurar/paises/paises/createCountryUtility'
        }),
        toggle(){
            this.resetForm();
            this.$refs.modalAddCountryUtility.toggle();
        },
        async addCountryUtility(){
            const valid = await this.$refs.validator.validate();
            
            if(!valid) return;

            await this.createCountryUtility(this.country)
            
            this.toggle();
        },
        resetForm(){
            this.country = CountryUtilityFactory.instantiateEmpty()
            this.$refs.validator.reset()
        }
    }
}
</script>